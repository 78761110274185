export const PERMISSAO = {
	IMPOSSIVEL: 1,
	CONVENIOS: 2,
	COISABOA: 3,
	ERROS: 4,
	QUASE_ACIDENTE: 5,
	RESUMO_DO_LIVRO: 6,
	TV_MARINS: 7,
	PLATAFORMA_MARINS: 8,
	FINANCEIRO: 9,
	LIVE: 36,
	FRASE_DA_SEMANA: 53,
	VIDEO_DA_SEMANA: 50,
	CADASTRAR_LIVE: 12,
	CADASTRAR_VIDEO_DA_SEMANA: 51,
	CADASTRAR_FRASE_DA_SEMANA: 54,
	APROVAR_OU_REJEITAR_ATIVIDADE: 14,
	GERENCIAR_GESTORES: 18,
	VER_ENVIOS_VIDEO_DA_SEMANA: 52,
	VER_ENVIOS_FRASE_DA_SEMANA: 55,
	VER_ENVIOS_LIVE: 29,
	VER_ENVIOS_COISA_BOA: 30,
	VER_ENVIOS_IMPOSSIVEL: 31,
	VER_ENVIOS_ERROS: 32,
	VER_ENVIOS_RESUMO_LIVRO: 33,
	VER_ENVIOS_TV_MARINS: 34,
	VER_ENVIOS_QUASE_ACIDENTE: 35,
	VER_ENVIOS_PLATAFORMA_MARINS: 37,
	GERENCIAR_PLATAFORMA_MARINS: 38,
	GERENCIAR_LINKS_PORMADE: 40,
	PONTOS: 41,
	GERENCIAR_PONTOS: 42,
	LANCAMENTO: 43,
	GERENCIAR_LANCAMENTO: 44,
	GERENCIAR_PESSOAS: 45,
	LIDER: 46,
	CADASTRAR_SETOR: 47,
	DELETAR_PLATAFORMA_MARINS: 48,
	GERENCIAR_PERMISSOES: 49,
	SORTEIO: 56,
	LANCAMENTO_MANUAL_LIVE: 57,
	GERENCIAR_QUIOSQUE: 10,
	COMPROVAR_PRESENCA_QUIOSQUE: 11,
	COPIA_E_COLA: 58,
	GESTOR: 59,
	CAFE_DIRECAO: 60,
	GERENCIAR_CAFE_DIRECAO: 61,
	GERENCIAR_MENSAGEM_LOGIN: 62,
	GERENCIAR_NOTIFICACOES: 63,
	LANCAMENTO_MANUAL_PETIM: 64,
	MASTER: 65,
};
