export function PlaceholderName(name: string): string {
	if (window.innerWidth > 920 || name.length < 15) return name;

	return name.slice(0, 15).concat('...');
}

export function ToUpperCaseText(text: string | undefined): string | undefined {
	if (!text) return;
	const arr = text.toLocaleLowerCase().split(' ');

	for (var i = 0; i < arr.length; i++) {
		arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
	}

	let textFormated = arr.join(' ');

	textFormated = textFormated.replaceAll(' Da ', ' da ').replaceAll(' De ', ' de ').replaceAll(' Do ', ' do ').replaceAll(' E ', ' e ');

	return textFormated;
}
